import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, interval, Subscription } from 'rxjs';
//import { ListCommentsComponent } from 'src/app/components/shared/dialogs/list-comments/list-comments.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { TransportorSocietyService } from 'src/app/services/transportor-society/transportor-society.service';
//import { TransportorsService } from 'src/app/services/transportors/transportors.service';
//import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  // subscription: Subscription;
  // intervalId: number = null;

  dataCmds: any = {}
  leadersList: Observable<Array<any>>;
  clientsList: Observable<Array<any>>;
  cmdList: any = []
  statusCmds: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService,
    //private trackingServices: UsersTrackingService,
    private dialog: MatDialog,
    private transServices: TransportorSocietyService) { }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.listCmdsByStatus()
    this.listLeaders()
    this.listClients()
    this.statisticFluxCmds()

    // const source = interval(10000);
    // this.subscription = source.subscribe(val => {
      // this.listLeaders()
      // this.listClients()
      // this.statisticFluxCmds()
    // });

    // this.intervalId = await setInterval(this.opensnack(text), 1000);
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  opensnack(text) {
    // I've just commented this so that you're not bombarded with an alert.
    // alert(text);
    // console.log(text);
  }

  ngOnDestroy() {
    // // For method 1
    // this.subscription && this.subscription.unsubscribe();

    // // For method 2
    // clearInterval(this.intervalId);
  }

  async listCmdsByStatus() {
    //let userId = await this.clientService.getIdUser()
    let filter: any
    this.cmdsService.allCmdsByStatus(null).then((res: any) => {
      // console.log("res")
      // console.log(res)
      if (res) {
        this.dataCmds = res;
      }
    });
  }
  async listLeaders() {
    await this.transServices.allSocieties().then((res: any) => {
      if (res) {
        // console.log("leader res");
        // console.log(res);
        this.leadersList = res;
      }

    });
  }
  async listClients() {
    await this.clientService.getClients().then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
      }
    });
  }
  async statisticFluxCmds() {
    await this.cmdsService.statisticFluxCmds().then((res: any) => {
      if (res) {
       // console.log("cmdssssss res");
      //  console.log(res);
        this.cmdList = res;
      }

    });
  }

  // listComments(cmd) {
  //   if (cmd.element.comments.length > 0) {
  //     const confirmDialog = this.dialog.open(ListCommentsComponent, {
  //       data: {
  //         cmd: cmd.element,
  //       }
  //     });
  //   }
  // }
  
  getTimeAgo(hourAgo, cmd) {
    let timeAgo = ""
    if (cmd.etat_cmd == "validated") {
      if (hourAgo < 0) {
        timeAgo += "-"
        hourAgo = hourAgo * -1
      }
      let diffDays = Math.floor(hourAgo / 86400000); // days
      let diffHrs = (Math.floor((hourAgo % 86400000) / 3600000)) + (diffDays * 24) // hours
      let diffMins = Math.round(((hourAgo % 86400000) % 3600000) / 60000); // minutes
      // timeAgo += diffHrs + ":" + diffMins
      timeAgo = cmd.client_cmd.datePickUp
    } else if (cmd.etat_cmd == "transporting" || cmd.etat_cmd == "transported" || cmd.etat_cmd == "arrived") {
      let diffDays = Math.floor(hourAgo / 86400000); // days
      let diffHrs = ((Math.floor((hourAgo % 86400000) / 3600000)) + (diffDays * 24)) - (diffDays * 13) // hours
      let diffMins = Math.round(((hourAgo % 86400000) % 3600000) / 60000); // minutes
      timeAgo += diffHrs + ":" + diffMins
    }
    return timeAgo
  }
  getBgColor(hourAgo, cmd) {
    // if (cmd.etat_cmd == "validated") {
    //   if (hourAgo < 0) {
    //     hourAgo = hourAgo * -1
    //     let diffDays = Math.floor(hourAgo / 86400000); // days
    //     let diffHrs = (Math.floor((hourAgo % 86400000) / 3600000)) + (diffDays * 24) // hours 
    //     if (diffHrs == 0) {
    //       return "bg-warning"
    //     } else {
    //       return ""
    //     }
    //   } else {
    //     return "bg-danger"
    //   }
    // } else if (cmd.etat_cmd == "transporting" || cmd.etat_cmd == "transported" || cmd.etat_cmd == "arrived") {
    //   let diffDays = Math.floor(hourAgo / 86400000); // days
    //   let diffHrs = ((Math.floor((hourAgo % 86400000) / 3600000)) + (diffDays * 24)) - (diffDays * 13) // hours 
    //   if (diffHrs <9) {
    //     return ""
    //   } else if(diffHrs <12) {
    //     return "bg-warning"
    //   } else {
    //     return "bg-danger"
    //   }
    // }
    // let nowDate: any = new Date()
    // let diff = nowDate - hourAgo
    // console.log(diff)
    return ""
    // let timeAgo = ""
    // if (hourAgo < 0) {
    //   timeAgo += "-"
    //   hourAgo = hourAgo * -1
    // }
    // let diffDays = Math.floor(hourAgo / 86400000); // days
    // let diffHrs = (Math.floor((hourAgo % 86400000) / 3600000)) + (diffDays * 24) // hours
    // let diffMins = Math.round(((hourAgo % 86400000) % 3600000) / 60000); // minutes
    // timeAgo += diffHrs + ":" + diffMins
    // return timeAgo

    // if(hourAgo<21) {
    //   return ""
    // } else if(hourAgo<24) {
    //   return "bg-warning"
    // } else {
    //   return "bg-danger"
    // }
  }
}
