import { Component, OnInit } from '@angular/core';
import { SocietiesService } from 'src/app/services/societies/societies.service';

@Component({
  selector: 'app-list-business-accounts',
  templateUrl: './list-business-accounts.component.html',
  styleUrls: ['./list-business-accounts.component.css']
})
export class ListBusinessAccountsComponent implements OnInit {

  allBusiness: any = []

  constructor(private societyService: SocietiesService) { }

  ngOnInit(): void {
    this.allBusinessAccounts()
  }

  async allBusinessAccounts() {
    await this.societyService.allBusinessAccounts().then((res: any) => {
      if (res) {
        this.allBusiness = res;
      }
    });
  }

}
