<div class="modal-header">
    <h4 class="modal-title">{{'data.send_confirm_email' | translate }}</h4>
    <button type="button" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h5 class="card-title">{{'data.providers'| translate }}</h5>
                    <div class="row">
                        <div class="col-12 col-md-6" *ngFor="let item of providerContacts, let index = index">
                            <div class="custom-checkbox custom-control form-check">
                                <input class="form-check-input" id="cmd_{{index}}" type="checkbox" name="cmd_{{index}}"
                                    [(ngModel)]="item.isChecked" [checked]="item.isChecked">
                                <label class="label-checkbox100" for="cmd_{{index}}">
                                    {{ item?.email }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h5 class="card-title">{{'data.Transport_companies' | translate}}</h5>
                    <div class="row">
                        <div class="col-12 col-md-6" *ngFor="let item of transContacts, let index = index">
                            <div class="custom-checkbox custom-control form-check">
                                <input class="form-check-input" id="trans_{{index}}" type="checkbox"
                                    name="trans_{{index}}" [(ngModel)]="item.isChecked" [checked]="item.isChecked">
                                <label class="label-checkbox100" for="trans_{{index}}">
                                    {{ item?.email }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="main-card mb-3 card">
                <div class="card-header-tab card-header">
                    <div class="card-header-title">
                        <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                        {{'data.other' | translate}}
                    </div>
                    <div class="btn-actions-pane-right">
                        <button type="button" (click)="addEmail()" class="mt-1 btn btn-warning">
                            <i class="pe-7s-plus"></i> {{'data.add' | translate}}
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <form [formGroup]="form">
                        <div formArrayName="emails" class="row">
                            <div *ngFor="let email of emails.controls; let i = index" class="col-6">
                                <div class="form-group position-relative row">
                                    <div class="col-12 col-md-10">
                                        <legend for="email-{{i}}" class="">Email {{i + 1}}</legend>
                                        <input name="email-{{i}}" id="email-{{i}}" type="email" class="form-control"
                                            [formControlName]="i">
                                    </div>
                                    <div class="col-12 col-md-2" style="margin-top: auto;">
                                        <button type="button" (click)="deleteEmail(i)"
                                            class="mr-2 btn-icon btn-icon-only btn btn-danger float-right">
                                            <i class="pe-7s-trash btn-icon-wrapper"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="sendMail()">{{'data.send' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{'data.close' | translate}}</button>
</div>