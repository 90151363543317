import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
//import { UsersTrackingService } from 'src/app/services/users-tracking/users-tracking.service';

@Component({
  selector: 'app-bon-paiement-delivery',
  templateUrl: './bon-paiement-delivery.component.html',
  styleUrls: ['./bon-paiement-delivery.component.css']
})
export class BonPaiementDeliveryComponent implements OnInit {

  idUser: string = '';
  userTracking: any = null;
  nowDate = new Date();

  cmdsLivred: any = []
  cmdsReturned: any = []
  listCmds: any = []
  fraisLivred: any = 0
  totalCmdsLivred: any = 0;

  constructor(private route: ActivatedRoute,
    //private trakingServices: UsersTrackingService,
    private cmdsServices: CommandesService,) { }

  async ngOnInit() {
    await this.route.queryParams.subscribe(async (params) => {
      this.idUser = params.userTracking;
     // this.getUserTracking()

    //  await this.detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress()
    });
  }
  // detailsCmdsTrackingNotPaiedAndNotReturnedAndInProgress() {
  //   this.trakingServices.allCmdsTrackingLivredReturnedAndInProgress(this.idUser).then(async (res: any) => {
  //     // console.log("allCmdsTrackingLivredReturnedAndInProgress .ts")
  //     // console.log(res)
  //     if (res) {
  //       this.cmdsLivred = res.cmdsLivred
  //       this.cmdsReturned = res.cmdsReturned
  //       this.listCmds = this.cmdsLivred.concat(this.cmdsReturned)
  //       this.fraisLivred = this.cmdsLivred.length * this.userTracking.frais_livraison
        
  //       // this.cmdsInProgress = res.cmdsInProgress
  //       this.totalCmdsLivred = await this.totalCmdsByStatus(this.cmdsLivred)
  //     }
  //   });
  // }
  async totalCmdsByStatus(array) {
    let total = 0
    array.forEach(element => {
      total += element.total_ca_cmd + element.frais_livraison
    });

    return total
  }
  // getUserTracking() {
  //   this.trakingServices.getOneUserTracking(this.idUser).then((user) => {
  //     if (user) {
  //       // console.log("userrrrrrr : ")
  //       // console.log(user)
  //       this.userTracking = user;
  //     }
  //   });
  // }

}
