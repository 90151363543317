import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatDialogModule } from '@angular/material/dialog';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { AddClientComponent } from './pages/clients/add-client/add-client.component';
import { AvatarModule } from 'ngx-avatar';
import { QRCodeModule } from 'angularx-qrcode';
import { CommandesClientComponent } from './pages/clients/commandes-client/commandes-client.component';
import { DetailCommandeClientComponent } from './pages/clients/detail-commande-client/detail-commande-client.component';
import { FraisClientComponent } from './pages/clients/frais-client/frais-client.component';
import { EditFraisClientComponent } from './pages/clients/edit-frais-client/edit-frais-client.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { CompteClientComponent } from './pages/clients/compte-client/compte-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { ReturnedCommandesComponent } from './pages/dashboard/returned-commandes/returned-commandes.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AllCommandesComponent } from './pages/clients/all-commandes/all-commandes.component';
import { FactureCmdComponent } from './pages/clients/facture-cmd/facture-cmd.component';
import { StatisticsCommandesComponent } from './pages/clients/statistics-commandes/statistics-commandes.component';
import { PickupCollectorComponent } from './components/shared/dialogs/pickup-collector/pickup-collector.component';
import { FacturePaiementClientComponent } from './pages/factures/facture-paiement-client/facture-paiement-client.component';
import { BonPaiementDeliveryComponent } from './pages/factures/bon-paiement-delivery/bon-paiement-delivery.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { NgReduxModule } from '@angular-redux/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SocietiesTransportorsComponent } from './pages/transportors-societies/societies-transportors/societies-transportors.component';
import { AddSocietyComponent } from './pages/transportors-societies/add-society/add-society.component';
import { ListBusinessAccountsComponent } from './pages/business-accounts/list-business-accounts/list-business-accounts.component';
import { AddBusinessAccountsComponent } from './pages/business-accounts/add-business-accounts/add-business-accounts.component';
import { DetailsSocietyComponent } from './pages/transportors-societies/details-society/details-society.component';
import { ContractSocietyComponent } from './pages/transportors-societies/contract-society/contract-society.component';
import { EditProviderComponent } from './pages/clients/edit-provider/edit-provider.component';
import { SendMailsComponent } from './components/shared/dialogs/send-mails/send-mails.component';
import { AllProductsComponent } from './pages/settings/products/all-products/all-products.component';
import { AllPalletsComponent } from './pages/settings/pallets/all-pallets/all-pallets.component';
import { ImportBoxesProductsComponent } from './pages/settings/products/windows/import-boxes-products/import-boxes-products.component';
import { AddProviderProductsComponent } from './components/shared/dialogs/add-provider-products/add-provider-products.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ImportProvidersComponent } from './pages/clients/windows/import-providers/import-providers.component';
import { EditTransportorSocietyComponent } from './pages/transportors-societies/edit-transportor-society/edit-transportor-society.component';
import { AddPalletComponent } from './pages/settings/pallets/windows/add-pallet/add-pallet.component';
import { AddBoxComponent } from './pages/settings/products/windows/add-box/add-box.component';
import { AddCommandeComponent } from './pages/clients/add-commande/add-commande.component';
import { AddBoxByProviderComponent } from './pages/settings/products/windows/add-box-by-provider/add-box-by-provider.component';
import { EditCommandeComponent } from './pages/clients/edit-commande/edit-commande.component';
import { AddTeamComponent } from './pages/teams/add-team/add-team.component';
import { TeamsListComponent } from './pages/teams/teams-list/teams-list.component';
import { SocietiesListCofComponent } from './pages/societies/societies-list-cof/societies-list-cof.component';
import { AddSocietyCofComponent } from './pages/societies/add-society-cof/add-society-cof.component';
import { EditTeamComponent } from './pages/teams/edit-team/edit-team.component';
import { EditSocietyCofComponent } from './pages/societies/edit-society-cof/edit-society-cof.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
    vertical: {
      position: 'top',
    },
  },
  theme: 'material',
};



@NgModule({
  declarations: [
    AppComponent,
    TopComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    ClientsComponent,
    AddClientComponent,
    CommandesClientComponent,
    DetailCommandeClientComponent,
    FraisClientComponent,
    EditFraisClientComponent,
    RechercheComponent,
    CompteClientComponent,
    StatusCommandesComponent,
    ReturnedCommandesComponent,
    AllCommandesComponent,
    FactureCmdComponent,
    StatisticsCommandesComponent,
    PickupCollectorComponent,
    FacturePaiementClientComponent,
    BonPaiementDeliveryComponent,
    SidebarComponent,
    BaseLayoutComponent,
    SocietiesTransportorsComponent,
    AddSocietyComponent,
    ListBusinessAccountsComponent,
    AddBusinessAccountsComponent,
    DetailsSocietyComponent,
    ContractSocietyComponent,
    EditProviderComponent,
    SendMailsComponent,
    AllProductsComponent,
    AllPalletsComponent,
    ImportBoxesProductsComponent,
    AddProviderProductsComponent,
    ImportProvidersComponent,
    EditTransportorSocietyComponent,
    AddPalletComponent,
    AddBoxComponent,
    AddCommandeComponent,
    AddBoxByProviderComponent,
    EditCommandeComponent,
    AddTeamComponent,
    TeamsListComponent,
    SocietiesListCofComponent,
    AddSocietyCofComponent,
    EditTeamComponent,
    EditSocietyCofComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),

    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('token-cofat')
      }
    }),

    QRCodeModule,
    AvatarModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
