import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AddClientComponent } from './pages/clients/add-client/add-client.component';
import { AllCommandesComponent } from './pages/clients/all-commandes/all-commandes.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { CommandesClientComponent } from './pages/clients/commandes-client/commandes-client.component';
import { CompteClientComponent } from './pages/clients/compte-client/compte-client.component';
import { DetailCommandeClientComponent } from './pages/clients/detail-commande-client/detail-commande-client.component';
import { EditFraisClientComponent } from './pages/clients/edit-frais-client/edit-frais-client.component';
import { FactureCmdComponent } from './pages/clients/facture-cmd/facture-cmd.component';
import { FraisClientComponent } from './pages/clients/frais-client/frais-client.component';
import { StatisticsCommandesComponent } from './pages/clients/statistics-commandes/statistics-commandes.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { RechercheComponent } from './pages/dashboard/recherche/recherche.component';
import { ReturnedCommandesComponent } from './pages/dashboard/returned-commandes/returned-commandes.component';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { FacturePaiementClientComponent } from './pages/factures/facture-paiement-client/facture-paiement-client.component';
import { BonPaiementDeliveryComponent } from './pages/factures/bon-paiement-delivery/bon-paiement-delivery.component';
import { AuthGuard } from './guard/auth.guard';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { SocietiesTransportorsComponent } from './pages/transportors-societies/societies-transportors/societies-transportors.component';
import { AddSocietyComponent } from './pages/transportors-societies/add-society/add-society.component';
import { AddBusinessAccountsComponent } from './pages/business-accounts/add-business-accounts/add-business-accounts.component';
import { ListBusinessAccountsComponent } from './pages/business-accounts/list-business-accounts/list-business-accounts.component';
import { DetailsSocietyComponent } from './pages/transportors-societies/details-society/details-society.component';
import { ContractSocietyComponent } from './pages/transportors-societies/contract-society/contract-society.component';
import { EditProviderComponent } from './pages/clients/edit-provider/edit-provider.component';
import { AllProductsComponent } from './pages/settings/products/all-products/all-products.component';
import { AllPalletsComponent } from './pages/settings/pallets/all-pallets/all-pallets.component';
import { EditTransportorSocietyComponent } from './pages/transportors-societies/edit-transportor-society/edit-transportor-society.component';
import { AddCommandeComponent } from './pages/clients/add-commande/add-commande.component';
import { EditCommandeComponent } from './pages/clients/edit-commande/edit-commande.component';
import { AddTeamComponent } from './pages/teams/add-team/add-team.component';
import { TeamsListComponent } from './pages/teams/teams-list/teams-list.component';
import { AddSocietyCofComponent } from './pages/societies/add-society-cof/add-society-cof.component';
import { SocietiesListCofComponent } from './pages/societies/societies-list-cof/societies-list-cof.component';
import { EditTeamComponent } from './pages/teams/edit-team/edit-team.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent, canActivate: [AuthGuard],
    children: [

      // Dashboads
      { path: '', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' } },
      { path: 'search', component: RechercheComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'all-commandes', component: AllCommandesComponent, data: { extraParameter: 'componentsMenu' } },

      { path: 'add-provider', component: AddClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-provider', component: EditProviderComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'providers', component: ClientsComponent, data: { extraParameter: 'componentsMenu' } },

      { path: 'add-commande', component: AddCommandeComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-commande', component: EditCommandeComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'account-provider', component: CompteClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'status-commandes', component: StatusCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'returned-commandes', component: ReturnedCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'statistics', component: StatisticsCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-cmds-client', component: CommandesClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-cmd', component: DetailCommandeClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'frais-client', component: FraisClientComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-frais-client', component: EditFraisClientComponent, data: { extraParameter: 'componentsMenu' } },

      // SOCIETY Cofat
      { path: 'society-cof', component: AddSocietyCofComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'all-societies-cof', component: SocietiesListCofComponent, data: { extraParameter: 'componentsMenu' } },

      // SOCIETY TRANSPORT
      { path: 'society-transportors', component: SocietiesTransportorsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'add-society', component: AddSocietyComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-society', component: EditTransportorSocietyComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'details-society', component: DetailsSocietyComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'contract-society', component: ContractSocietyComponent, data: { extraParameter: 'componentsMenu' } },

      // SETTINGS
      { path: 'all-products', component: AllProductsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'all-pallets', component: AllPalletsComponent, data: { extraParameter: 'componentsMenu' } },

      { path: 'add-business-account', component: AddBusinessAccountsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'business-accounts', component: ListBusinessAccountsComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-business-account', component: AddBusinessAccountsComponent, data: { extraParameter: 'componentsMenu' } },

      //teams
      { path: 'add-team', component: AddTeamComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'edit-team', component: EditTeamComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'all-teams', component: TeamsListComponent, data: { extraParameter: 'componentsMenu' } },
    ]

  },
  { path: '', redirectTo: '', pathMatch: 'full' },

  // { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  // 

  { path: 'facture-cmd', component: FactureCmdComponent, canActivate: [AuthGuard] },
  { path: 'facture-paiement-client', component: FacturePaiementClientComponent, canActivate: [AuthGuard] },
  { path: 'bon-paiement-delivery', component: BonPaiementDeliveryComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
