import { Component, OnInit } from '@angular/core';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import { TransportorSocietyService } from 'src/app/services/transportor-society/transportor-society.service';
//import { TransportorsService } from 'src/app/services/transportors/transportors.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-societies-transportors',
  templateUrl: './societies-transportors.component.html',
  styleUrls: ['./societies-transportors.component.css']
})
export class SocietiesTransportorsComponent implements OnInit {

  isLoading: any = true;
  allSocties: any = []

  constructor(private service: TransportorSocietyService,
    private sweetalert: SweetalertService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.allSocieties()
  }

  async allSocieties() {
    await this.service.allSocieties().then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.isLoading = false
        this.allSocties = res;
      }
    });
  }

  deleteSociety(idTransSociety: any) {
    this.sweetalert.confirmActionBtn(this.translate.instant('data.warning_text')).then((result: any) => {
      if (result.isConfirmed) {
        this.service.deleteSociety(idTransSociety).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this.allSocieties()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }

}
