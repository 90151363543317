import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';
import { AddPalletComponent } from '../windows/add-pallet/add-pallet.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-all-pallets',
  templateUrl: './all-pallets.component.html',
  styleUrls: ['./all-pallets.component.css']
})
export class AllPalletsComponent implements OnInit {

  isLoading: any = true;
  pallets: any[] = []

  constructor(private service: SettingsService,
    private sweetalert: SweetalertService,
    private modalService: NgbModal,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this._fetchPalettes()
  }

  async _fetchPalettes() {
    await this.service.allPallets().then((res: any) => {
      if (res) {
        this.isLoading = false
        this.pallets = res.data
      }
    });
  }

  newPallet() {
    var pallet = {
      name: "",
      "hauteur": null,
      "largeur": null,
      "longueur": null
    }
    this.savePallet(pallet)
  }
  savePallet(pallet: any) {
    // console.log(pallet)
    const modalRef = this.modalService.open(AddPalletComponent, { size: 'lg' });
    modalRef.componentInstance.pallet = pallet
    
    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("Result from modal:", receivedEntry);
        this._fetchPalettes()
      }
    });
  }
  deletePallet(idPallet: any) {
    this.sweetalert.confirmActionBtn(this.translate.instant('data.warning_text')).then((result: any) => {
      if (result.isConfirmed) {
        this.service.deletePallet(idPallet).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this._fetchPalettes()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }

}
