import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';

@Component({
  selector: 'app-import-providers',
  templateUrl: './import-providers.component.html',
  styleUrls: ['./import-providers.component.css']
})

export class ImportProvidersComponent implements OnInit {

  @Output() result = new EventEmitter<any>();

  @ViewChild('file')
  myfileImport: ElementRef;

  // msgError: string = null
  isLoading: boolean
  itemsError: any = []

  isSaved: Boolean = false
  providers: any[] = []
  idSociety: string;

  constructor(public activeModal: NgbActiveModal,
    private service: ClientsService,
    private teamsServices: TeamsService) {
    this.idSociety = this.teamsServices.getIdSociety();
  }

  ngOnInit(): void {
  }

  docIsValid(array) {
    return true
  }
  readExcel(event) {
    this.isLoading = true

    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    /**
     * Final Solution For Importing the Excel FILE
     */
    // this.msgError = null
    // this.labelType = ""
    // this.isFedex = false
    // this.isTNT = false
    this.providers = []
    const header = [] //["nameDest", "adressDest", "numTelDest", "destination", "nom_prod", "quantite", "prix_unitaire"]

    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then((data) => {
      workbook.xlsx.load(data)
        .then(async () => {

          // play with workbook and worksheet now
          // console.log(workbook);
          if (workbook.worksheets.length === 0) {
            // Afficher un message d'erreur ou le gérer selon les besoins de votre application
            alert("Erreur : Le classeur ne contient aucune feuille de calcul !!!")
            return;
          }

          const worksheet = workbook.worksheets[0];

          // console.log('rowCount: ', worksheet.getRow(1).values);
          if (!this.docIsValid(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))
                /*&& !this.docIsTNT(worksheet.getRow(1).values.map(Function.prototype.call, String.prototype.trim))*/) {
            // console.log("file incorrecte !!!")
            alert("Impossible d'importer le fichier excel à cause d'un champ manquant")
            return;
          }

          const promises = [];
          // worksheet.
          worksheet.eachRow((row, rowNumber) => {
            // console.log('Row: ' + rowNumber + ' Value: ' + row.values);
            // console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values));
            let provider = {}
            if (rowNumber == 1) {
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                header.push(cell.value.toString().trim())
              });

            } else {

              // const promise = db.insert(row); // <-- whatever async operation you have here
              row.eachCell((cell, colNumber) => {
                // console.log('Cell ' + colNumber + ' , ' + header[colNumber - 1] + ' = ' + cell.value);
                provider[header[colNumber - 1]] = this.getValue(this.trimIfString(cell.value))
              });
              promises.push({ provider: provider, msg: null });
            }
          });
          this.providers = await Promise.all(promises)
          // console.log("all boxes : ")
          // console.log(this.providers)

          this.isLoading = false
        });
    });

    this.myfileImport.nativeElement.value = "";
  }
  trimIfString(value: any): any {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  }
  getValue(item) {
    // console.log("itemmmmmmm")
    // console.log(item)
    if (item != null && item.hasOwnProperty("result")) {
      return item.result
    } else {
      return item
    }
  }
  chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
  addProvider(item) {
    //console.log("resssssssss : ")
    //console.log(item)
    let promise = new Promise((resolve, reject) => {
      this.service.subscribe({
        providerCode: item.provider['t_bpid'],
        providerName: item.provider['t_nama'],
        providerDescription: item.provider['t_namc'],
        numTel_society: item.provider['t_telp'],

        adressSocietyLabel: item.provider['Adresse complète'],
        //country: item.provider['t_ccit'],
        society: this.idSociety,
        email_society: item.provider['emailSty']
      })
        .then((res: any) => {
          if (res) {
            if (!res?.response) {
              //console.log("resssssssss : ")
              //console.log(res)
            }
            resolve(res);
          }
        });
    });
    return promise
  }

  async saveProviders() {
    let promises: Promise<any>[] = [];
    this.providers.forEach(item => {
      let promise: Promise<any>
      // if(this.isFedex) {
      promise = this.addProvider(item).then((value: any) => {
        return { provider: item.provider, msg: value };
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((results) => {
        // console.log(results)
        this.providers = results.filter(item => item.msg != null && !item.msg.response);
        if (this.providers?.length == 0)
          this.closeModal()
      })
  }

  closeModal() {
    this.result.emit(true);
    this.activeModal.dismiss();
  }

}
