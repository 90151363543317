import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddProviderProductsComponent } from 'src/app/components/shared/dialogs/add-provider-products/add-provider-products.component';
import { PickupCollectorComponent } from 'src/app/components/shared/dialogs/pickup-collector/pickup-collector.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { SweetalertService } from 'src/app/services/sweetalert/sweetalert.service';
import Swal from 'sweetalert2';
import { ImportProvidersComponent } from '../windows/import-providers/import-providers.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  isLoading: any = true;
  clientsList: any[] = [];

  filter: any = {}

  countries: any[] = [
    "Allemagne", "Autriche", "Belgique",
    "Bulgarie", "Chypre", "Croatie",
    "Danemark", "Espagne", "Estonie",
    "Finlande", "France", "Grèce",
    "Hongrie", "Irlande", "Italie",
    "Lettonie", "Lituanie", "Luxembourg",
    "Malte", "Pays-Bas", "Pologne",
    "Portugal", "République tchèque",
    "Roumanie", "Slovaquie", "Slovénie",
    "Suède"
  ]


  constructor(private clientsService: ClientsService,
    private sweetalert: SweetalertService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.listClients()
  }

  async filterProviders() {
    // console.log(this.filter)
    await this.clientsService.searchClient(this.filter).then((res: any) => {
      // console.log(res)
      if (res) {
        this.clientsList = res;
      }
    });
  }

  async listClients() {
    await this.clientsService.getClients().then((res: any) => {
      console.log(res)
      if (res) {
        this.isLoading = false
        this.clientsList = res;
      }
    });
  }

  // async getClient(keyword) {
  //   await this.clientsService.searchClient(this.filter).then((res: any) => {
  //     // console.log(res)
  //     if (res) {
  //       this.clientsList = res;
  //     }
  //   });
  //   keyword.value = ""
  // }


  async pickUpCollector(idClient) {
    // console.log("amineeeeeeeeee")
    const confirmDialog = this.dialog.open(PickupCollectorComponent, {
      data: {
        // hourPickUp: null,
        collector: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data)
        // console.log("idSac : ")
        // console.log(idSac)
        this.clientsService.affecteCollecteur(idClient, data.collector/*, data.hourPickUp*/).then((res: any) => {
          // console.log("pickUpCollector : ");
          // console.log(res);
          if (res && !res.response) {
            alert(this.translate.instant('data.collect_affect'))
            this.listClients()
          }
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        // this.listSacs();
      }
    });
  }

  deleteProvider(idProvider: any) {
    this.sweetalert.confirmActionBtn(this.translate.instant('data.warning_text')).then((result: any) => {
      if (result.isConfirmed) {
        this.clientsService.deleteClient(idProvider).then((res: any) => {
          if (res && res.response == true) {
            this.sweetalert.alertConfirmed(res?.msg).then((res: any) => {
              this.listClients()
            })
          }
        });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.sweetalert.alertCanceled().then((res: any) => {
        })
      }
    })
  }

  addProviderProducts(provider) {
    const modalRef = this.modalService.open(AddProviderProductsComponent, { size: 'lg' });
    modalRef.componentInstance.provider = provider

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      // console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("Result from modal:", receivedEntry);
        this.listClients()
      }
    });
  }

  importProviders() {
    const modalRef = this.modalService.open(ImportProvidersComponent, { size: 'lg' });

    modalRef.componentInstance.result.subscribe((receivedEntry) => {
      //console.log(receivedEntry)
      if (receivedEntry) {
        // console.log("Result from modal:", receivedEntry);
        this.listClients()
      }
    });
  }
}
