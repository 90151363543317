<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content style="width: 450px;">
    <h1 style="text-align:center"><strong></strong></h1>
    <h2 style="text-align:center;font-size: x-large; color: rgb(194, 192, 192);">{{'data.select_collector'| translate}}
    </h2>
    <div class="div-form">
        <div class="form-group">
            <!-- <div class="col-12">
                <div class="position-relative form-group">
                    <legend for="hourPickUp" class="">L'heure de pickup :</legend>
                    <input id="hourPickUp" type="time" class="form-control" name="hourPickUp" 
                    tabindex="1" [(ngModel)]="data.hourPickUp">
                    <mat-error style="color: red;font-weight: bold;" *ngIf="data.hourPickUp==null || data.hourPickUp=='null'">
                        Selectionner l'heure du pickup
                    </mat-error>
                </div>
            </div> -->
            <div class="col-12">
                <div class="position-relative form-group">
                    <legend for="collectorPickUp" class="">{{'data.enter_collector' | translate}} :</legend>
                    <select class="form-control selectric" (change)="selectChangeHandler($event)">
                        <option [value]="null">{{'data.select_collect' | translate}}</option>
                        <option [value]="collector._id" *ngFor="let collector of collectorList">
                            {{collector.nameSociety}}
                        </option>
                    </select>
                    <mat-error style="color: red;font-weight: bold;"
                        *ngIf="data.collector==null || data.collector=='null'">
                        {{'data.select_collect' | translate}}
                    </mat-error>
                </div>
            </div>
            <!-- <label style="font-size: medium; color: white;">Entrer le collecteur :</label> -->
            <!-- <div class="d-block">
          <label for="ville">Ville</label>
        </div>
        <input id="ville" type="text" class="form-control" name="ville" tabindex="2" #ville ngModel> -->
            <!-- <div class="invalid-feedback">
        Mot Obligatoire
        </div> -->
        </div>
    </div>
</div>

<div class="row float-right margin-top">
    <button style="margin-right: 15px;" [mat-dialog-close]="false" class="btn btn-info"> {{'data.no' |
        translate}} </button>

    <button style="margin-right: 15px;" [mat-dialog-close]="true" class="btn btn-danger"
        *ngIf="data.collector!=null && data.collector!='null'"> {{'data.yes' | translate}} </button>
</div>