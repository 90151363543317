import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-commandes-client',
  templateUrl: './commandes-client.component.html',
  styleUrls: ['./commandes-client.component.css']
})

export class CommandesClientComponent implements OnInit {

  isLoading: any = true;

  cmdList: Observable<Array<any>>;
  idClient: string = "";
  client: any = {}

  statusCmds: any = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private clientsService: ClientsService,
    private CmdsServices: CommandesService
  ) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.route.queryParams.subscribe((params) => {
      this.idClient = params.client;
      this.getClient()
      this.listCmdsClient()
    });

  }

  async listCmdsClient() {
    await this.clientsService.getCmdsClient(this.idClient).then((res: any) => {
      console.log(res)
      if (res) {
        this.isLoading = false
        this.cmdList = res;
      }
    });
  }

  async getClient() {
    await this.clientsService.getClient(this.idClient).then((res: any) => {
      // console.log(res)
      if (res) {
        this.client = res;
      }
    });
  }

  goToNewTab(idCmd, route) {
    this.CmdsServices.goToNewTab(idCmd, route)
  }

}
