<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                        <div class="widget-content-left">
                            <ngx-avatar class="rounded-circle" name="{{client.prenom_client}}
                            {{client.nom_client}}">
                            </ngx-avatar>
                        </div>
                    </div>
                    <div class="widget-content-left flex2">
                        <div class="widget-heading">{{client.prenom_client}}
                            {{client.nom_client}}</div>
                        <div class="widget-subheading opacity-7">{{client.providerName}}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="page-title-icon">
                <i class="pe-7s-users icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commandes
                <div class="page-title-subheading">La liste des commandes du client xxxx.
                </div>
            </div> -->
        </div>
    </div>
</div>

<div *ngIf="isLoading; else elseAllCmds">
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-body text-center">
                    <div class="spinner-container blue-spinner">
                        <mat-progress-spinner mode="indeterminate" [diameter]="40"
                            [strokeWidth]="5"></mat-progress-spinner>
                    </div>
                </div>
                <!-- <div style="height: 70px;" [nbSpinner]="loading" nbSpinnerStatus="warning" nbSpinnerSize="giant">
            </div> -->
            </div>
        </div>
    </div>
</div>
<ng-template #elseAllCmds>
    <div class="row">
        <div class="col-md-12">
            <div class="main-card mb-3 card">
                <div class="card-header">{{'data.pickups' | translate}}
                    <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->
                </div>
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th>{{'data.provider' | translate}}</th>
                                <th>{{'data.reference' | translate}}</th>
                                <th>{{'data.date_pickup' | translate}}</th>
                                <th class="text-center">{{'data.products' | translate}}<br>
                                    {{'data.quantity_product_name' | translate}}</th>
                                <th>{{'data.comment' | translate}}</th>
                                <th class="text-center">{{'data.volume' | translate}}<br>{{'data.boxes' |
                                    translate}}<br>(m³)</th>
                                <th class="text-center">{{'data.volume' | translate}}<br>{{'data.pallet' |
                                    translate}}<br>(m³)</th>
                                <th class="text-center">{{'data.total_weight' | translate}} <br>total (Kg)</th>
                                <th class="text-center">{{'data.status' | translate}}</th>
                                <th class="text-center">Total (€) </th>
                                <th class="text-center">{{'data.old' | translate}}<br>Total (€)</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cmd of cmdList, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <ngx-avatar class="rounded-circle" name="{{cmd?.client_cmd?.prenom_client}}
                                            {{cmd?.client_cmd?.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading">
                                                    {{cmd?.client_cmd?.providerName}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{cmd?.client_cmd?.shippingAdress?.label_target}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{cmd?.ref_facture_cmd}}</td>
                                <td class="text-center">
                                    {{cmd.pickupDate | date:'d MMM y'}}<br>
                                    <b>{{cmd.pickupDate | date:'HH:mm'}}</b>
                                </td>
                                <td>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalBoxes > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/box.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalBoxes }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                                        *ngIf="cmd?.totalPallets > 0">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3 widget-pallet">
                                                        <img width="100%"
                                                            src="https://assets.marketbey.tn/WTM/products/pallet.png"
                                                            alt="">
                                                    </div>
                                                    <div class="widget-content-right">
                                                        <div class="font-size-lg text-muted">
                                                            <span>
                                                                {{ cmd?.totalPallets }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                                <td class="text-center">
                                    <span class="badge badge-pill badge-danger"
                                        (click)="listComments(cmd)">{{cmd.comments?.length}}</span>
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalVolume | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPalletVolume | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    {{ cmd?.totalPoids | number:'0.1' }}
                                </td>
                                <td class="text-center">
                                    <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                                        {{statusCmds[cmd.etat_cmd].label}}</div>
                                </td>
                                <td class="text-center">{{cmd.total_ca_cmd + cmd.frais_livraison |
                                    number:'0.3'}} EUR</td>
                                <td></td>
                                <td class="text-center">
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button" (click)="goToNewTab(cmd._id, '/detail-cmd')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>{{'data.details' | translate}}
                                            </button>
                                            <button type="button" tabindex="0"
                                                (click)="goToNewTab(cmd._id, '/facture-cmd')" class="dropdown-item"><i
                                                    class="metismenu-icon pe-7s-print"
                                                    style="margin-right: 10px;"></i>{{'data.invoice' |
                                                translate}}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="d-block text-center card-footer">
                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                <button class="btn-wide btn btn-success">Save</button>
            </div> -->
            </div>
        </div>
    </div>
</ng-template>