import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { map, startWith } from 'rxjs/operators';
import { TeamsService } from 'src/app/services/teams-cofat/teams.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {

  timer: any[] = [
    { "value": "06:00" },
    { "value": "06:30" },
    { "value": "07:00" },
    { "value": "07:30" },
    { "value": "08:00" },
    { "value": "08:30" },
    { "value": "09:00" },
    { "value": "09:30" },
    { "value": "10:00" },
    { "value": "10:30" },
    { "value": "11:00" },
    { "value": "11:30" },
    { "value": "12:00" },
    { "value": "12:30" },
    { "value": "13:00" },
    { "value": "13:30" },
    { "value": "14:00" },
    { "value": "14:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "15:00" },
    { "value": "15:30" },
    { "value": "16:00" },
    { "value": "16:30" },
    { "value": "17:00" },
    { "value": "17:30" },
    { "value": "18:00" },
    { "value": "18:30" },
    { "value": "19:00" },
    { "value": "19:30" },
    { "value": "20:00" },
    { "value": "20:30" },
    { "value": "21:00" },
    { "value": "21:30" },
    { "value": "22:00" },
    { "value": "22:30" },
    { "value": "23:00" },
    { "value": "23:30" },
    { "value": "00:00" },
    { "value": "00:30" },
    { "value": "01:00" },
    { "value": "01:30" },
    { "value": "02:00" },
    { "value": "02:30" },
    { "value": "03:00" },
    { "value": "03:30" },
    { "value": "04:00" },
    { "value": "04:30" },
    { "value": "05:00" },
    { "value": "05:30" },
  ]
  incoterms: any[] = [
    "EXW",
    "FCA usine",
    "FCA plateforme transporteur"
  ]
  posts: any[] = [
    "Responsable Logistique",
    "Financière",
    "Exploitation",
    "Autre"
  ]
  countries: any[] = [
    "Allemagne", "Angleterre", "Autriche", "Belgique",
    "Bulgarie", "Chypre", "Croatie",
    "Danemark", "Espagne", "Estonie",
    "Finlande", "France", "Grèce",
    "Hongrie", "Irlande", "Italie",
    "Lettonie", "Lituanie", "Luxembourg",
    "Malte", "Pays-Bas", "Pologne",
    "Portugal", "République tchèque",
    "Roumanie", "Slovaquie", "Slovénie",
    "Suisse", "Suède"
  ]
  // typesMarch: any[] = [
  //   "Électronique",
  //   "Produits Alimentaires",
  //   "Vêtements"
  // ]

  places: any[] = [];
  @ViewChild('autoInput') input: any;
  @ViewChild('autoInput2') input2: any;
  // myControl = new FormControl();
  // options: string[] = ['One', 'Two', 'Three'];
  // filteredOptions: Observable<string[]>;


  isSaved: Boolean = true

  provider: any = {
    // providerMail: null,
    providerName: '',
    providerDescription: '',
    providerCode: '',
    numberSiret: '',
    logoSociety: "",

    email_society: "",
    numTel_society: "",

    firstName: '',
    lastName: '',
    username: '',

    labelPost: "",
    post: "",

    numTel: '',
    numTel2: '',
    password: '',
    // confirmPassword: '',

    adressSociety: null,
    shippingAdress: null,
    stackable: false,
  }

  env: any;
  // imageUrl: string = '';
  // fileToUpload: File = null;
  idSociety: string;
  formContact!: FormGroup;

  constructor(private clientsService: ClientsService,
    private fb: FormBuilder,
    private teamsService: TeamsService,
    private translate: TranslateService) {
    this.env = environment
    this.formContact = this.fb.group({
      contacts: this.fb.array([])
    })
    this.idSociety = this.teamsService.getIdSociety();
  }

  ngOnInit(): void {
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );
    // this.addNewContactGroup()
  }
  get contacts(): FormArray {
    return this.formContact.get("contacts") as FormArray;
  }

  async onChange(input: string) {
    if (input === "adressSociety")
      this.places = await this._fetchAllPlace(this.input.nativeElement.value)
    else
      this.places = await this._fetchAllPlace(this.input2.nativeElement.value)

    // this.getFilteredOptions(this.input.nativeElement.value);
  }
  async onSelectionChange(event: any, input: string) {
    // console.log(event.option.value)
    const selectedValue = event.option.value;
    this.places = [] //this.getFilteredOptions($event);
    var detailsPlaceFrom = await this._fetchDetailsPlace(selectedValue.place_id)
    // console.log(detailsPlaceFrom)

    const country = this.getCountry(detailsPlaceFrom)
    var adress = {
      lat: detailsPlaceFrom.geometry.location.lat,
      lng: detailsPlaceFrom.geometry.location.lng,
      label_target: selectedValue.description,
      country: country
    }
    // console.log("adresssssssssss :::::::::::: ")
    // console.log(adress)
    // console.log(country)

    if (input === "adressSociety") {
      this.input.nativeElement.value = selectedValue.description
      this.provider.adressSociety = adress

      this.provider.fullAddress = selectedValue.description
      this.provider.country = country
    } else {
      this.input2.nativeElement.value = selectedValue.description
      this.provider.shippingAdress = adress
    }

    // console.log(this.provider.adressSociety)
    // this.positionChanged.emit(new PositionModel(
    //   detailsPlaceFrom.geometry.location.lat,
    //   detailsPlaceFrom.geometry.location.lng,
    //   $event.description
    // ));
  }
  getCountry(detailsPlaceFrom: any) {
    if (detailsPlaceFrom && detailsPlaceFrom?.address_components
      && detailsPlaceFrom?.address_components?.length > 0) {
      const countryComponent = detailsPlaceFrom?.address_components.find((component: any) => {
        return component.types.includes("country");
      });

      return countryComponent ? countryComponent.long_name : null;
    }
    return null
  }
  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }

  /** BEGIN SERVICE GOOGLE API */
  _fetchAllPlace(place: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.searchPlace(place).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res.predictions)
        }
      }).catch((e: any) => {
        resolve([])
      });
    });
    return promise
  }
  _fetchDetailsPlace(placeId: string) {
    let promise = new Promise<any>(async (resolve, reject) => {
      await this.clientsService.detailsPlace(placeId).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          // this.listPlacesFrom = 
          resolve(res)
        }
      }).catch((e: any) => {
        resolve(null)
      });
    });
    return promise
  }
  /** END SERVICE GOOGLE API */

  // handleFileInput(file: FileList) {
  //   this.fileToUpload = file.item(0);
  //   var reader = new FileReader();
  //   reader.onload = (event: any) => {
  //     this.imageUrl = event.target.result;
  //   }
  //   reader.readAsDataURL(this.fileToUpload);
  // }

  checkPost() {
    if (this.isOther(this.provider.labelPost)) {
      if (this.provider.post != null && this.provider.post != "")
        return true
      else
        return false
    } else
      return true
  }

  checkField() {
    if (this.provider.providerName != null && this.provider.providerName != "" &&
      this.provider.providerCode != null && this.provider.providerCode != "" &&
      this.provider.email_society != null && this.provider.email_society != "" &&
      this.provider.numTel_society != null && this.provider.numTel_society != "" &&
      this.provider.incoterm != null && this.provider.incoterm != "" &&
      this.provider.adressSociety != null &&
      this.provider.firstName != null && this.provider.firstName != "" &&
      this.provider.lastName != null && this.provider.lastName != "" &&
      this.provider.labelPost != null && this.provider.labelPost != "" && this.checkPost() &&
      this.provider.username != null && this.provider.username != "" &&
      this.provider.numTel != null && this.provider.numTel != "" &&
      this.provider.password != null && this.provider.password != ""
    )
      return true
    else
      return false
  }

  // checkPassword() {
  //   if (this.provider.password != this.provider.confirmPassword)
  //     return true
  //   else return false
  // }

  async addProvider() {
    // console.log(this.provider)
    this.provider.contacts = this.formContact.value.contacts
    var isValid = this.checkField()
    // var isEgal = this.checkPassword()

    if (!isValid) {
      this.isSaved = isValid
      alert("Merci de remplir les champs obligatoire !!!")
    }
    // else if (!isEgal) {
    //   alert("Mot de passe incorrect")
    // } 
    else {
      if (this.idSociety != null) {
        this.provider.society = this.idSociety
      }
      await this.clientsService.subscribe(this.provider)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            if (res?.response) {
              location.href = '/providers';
            } else
              alert(res.msg)
            //this.sacsList = res;
          } else
            alert("Probléme reproduit !!!")
        });
    }
  }


  showAlert() {
    this.translate.get('ALERT_PROBLEM').subscribe((res: string) => {
        alert(res);
    });
}

  handleFileInput(event: any) {
    const element = event.currentTarget as HTMLInputElement;
    const filelist: FileList | null = element.files;
    if (filelist) {
      const fileToUpload = filelist[0];
      var reader = new FileReader();
      // reader.onload = (event: any) => {
      //   // this.imageUrl = event.target.result;
      // }
      reader.readAsDataURL(fileToUpload);

      this.uploadPhoto(fileToUpload)
    }
  }

  async uploadPhoto(fileToUpload: any) {
    const formData: any = new FormData();

    if (fileToUpload != null) {
      formData.append("photo", fileToUpload, fileToUpload['name']);
    }

    var profile: any = {
      photo_name: "logo-",
      folder_name: "providers/logo/"
    }

    for (var key in profile) {
      formData.append(key, profile[key]);
    }

    await this.teamsService.uploadPhoto(formData)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          if (res.response)
            this.provider.logoSociety = res.data
          else
            alert(res?.msg)
        } else
          alert("Probléme occured")
      });
  }

  addNewContactGroup() {
    this.contacts.push(this.fb.group({
      firstName: [],
      lastName: [],
      labelPost: [],
      post: [],
      email: [],
      numTel: [],
      numTel2: []
    }))
  }
  deleteContactGroup(index: number) {
    if (this.formContact.value.contacts.length > 1) {
      this.contacts.removeAt(index);
    }
  }
  isOther(item: any) {
    return item == "Autre"
  }
  selectPost(event: any, index: any) {
    var valuePost = event.target.value

    const add = this.formContact.get('contacts') as FormArray;
    add.at(index).patchValue({
      post: (valuePost != "Autre") ? valuePost : null
    });
  }
  selectContactPost(event: any) {
    var valuePost = event.target.value

    if (valuePost != "Autre") {
      this.provider.post = null
    }
  }

}
